import "./cardlists.scss"

import React from "react"

export default (props) => {
    return (
        <section>
            <div className="row padding-t-6">
                {props.cardlists.map((service, index, array) => {
                    return (
                        <React.Fragment key={index}>
                            <div className="col-xs-12 col-sm-6 text-center margin-b-6-sm padding-l-6-sm padding-r-6-sm">
                                <img src={process.env.filesBaseUrl+service.icon.publicUrl} alt={service.alt} className="fill cardlists-icon"/>
                                <p className="title">{service.title}</p>
                                <p className="text-medium">{service.body}</p>
                            </div>
                            <div className="col-xs-12 col-sm-6 margin-b-6-sm padding-t-6-sm">
                                <ul className="cardlists-list">
                                    <li className="text-medium">{service.firstPoint}</li>
                                    <li className="text-medium">{service.secondPoint}</li>
                                    <li className="text-medium">{service.thirdPoint}</li>
                                </ul>
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        </section>
    )
}